import { RecommendationsParametersStrategyEnum as Strategy } from "@xxl/recommendations-api";
import type { Nullable } from "@xxl/type-utils";
import { useEffect, useState } from "react";
import { color as colors } from "@xxl/theme";
import { hexToRGB } from "react-app/src/utils/xxl-hex-to-rgb";
import type { DeepNonNullable } from "utility-types";
import type * as CSSType from "csstype";
import { logError } from "react-app/src/utils/xxl-log";

type Only<T, U> = {
  [P in keyof T]: T[P];
} & {
  [P in keyof U]?: never;
};

type Either<T, U> = Only<T, U> | Only<U, T>;

type UniqueUpsaleProps = {
  price: number;
} & WithCategories;

type UniqueRecentlyViewedProps = {
  includedProducts: string[];
  sessionId?: string;
};

type UniqueAccessoriesProps = {
  productImage: string;
} & WithCategories;

type UniquePersonalizedProps = {
  brands?: string[];
  campaigns?: string[];
  categories?: string[];
  includedProducts?: string[];
};

type UniquePopularityProps = {
  categories: string[];
};

type CommonBestellerProps = {
  nrOfDays: number;
};

type WithBrands = {
  brands: string[];
};

type WithCategories = {
  categories: string[];
};

type UniqueBestsellerProps =
  | (CommonBestellerProps & Either<WithBrands, WithCategories>)
  | (CommonBestellerProps & WithBrands & WithCategories);

type CommonProps = {
  name: string;
  productsCount: number;
  fetchDataOnLoad?: boolean;
  paddingTop?: CSSType.Property.PaddingTop;
  sliderStyles?: React.CSSProperties;
  styleId?: string;
  type?: "SELF_CONTAINED" | "SUB_COMPONENT";
  campaigns?: string[];
  includedProducts?: string[];
};

type UseUniquePropsReturn = Nullable<DeepNonNullable<UniqueUpsaleProps>> &
  Nullable<DeepNonNullable<UniqueRecentlyViewedProps>> &
  Nullable<DeepNonNullable<UniqueAccessoriesProps>> &
  Nullable<DeepNonNullable<UniquePersonalizedProps>> &
  Nullable<DeepNonNullable<UniqueBestsellerProps>> &
  Nullable<DeepNonNullable<UniquePopularityProps>>;

export type HandlerProps =
  | (CommonProps & {
      strategy: Strategy.frequently_bought_together;
    })
  | (UniqueUpsaleProps & CommonProps & { strategy: Strategy.upsale })
  | (UniqueAccessoriesProps & CommonProps & { strategy: Strategy.accessories })
  | (UniqueRecentlyViewedProps & CommonProps & { strategy: Strategy.recently })
  | (UniquePersonalizedProps &
      CommonProps & { strategy: Strategy.personalized })
  | (UniquePopularityProps & CommonProps & { strategy: Strategy.popularity })
  | (UniqueBestsellerProps & CommonProps & { strategy: Strategy.bestseller });

export const getAccessoriesProps = (props: HandlerProps) => {
  return props.strategy === Strategy.accessories
    ? { categories: props.categories, productImage: props.productImage }
    : { categories: [], productImage: "" };
};

export const useUniqueProps = (
  props: HandlerProps
): UseUniquePropsReturn | null => {
  const [uniqueProps, setUniqueProps] = useState<UseUniquePropsReturn | null>(
    null
  );
  useEffect(() => {
    const isUpsale = props.strategy === Strategy.upsale;
    const isAccessories = props.strategy === Strategy.accessories;
    const isRecently = props.strategy === Strategy.recently;
    const isPersonalized = props.strategy === Strategy.personalized;
    const isPopularity = props.strategy === Strategy.popularity;
    const isBestseller = props.strategy === Strategy.bestseller;

    setUniqueProps({
      brands:
        (isPersonalized || (isBestseller && props.brands !== undefined)
          ? props.brands
          : null) ?? null,
      campaigns: (isPersonalized ? props.campaigns : null) ?? null,
      categories:
        (isPersonalized ||
        isPopularity ||
        isAccessories ||
        isUpsale ||
        (isBestseller && props.categories !== undefined)
          ? props.categories
          : null) ?? null,
      nrOfDays: isBestseller ? props.nrOfDays : null,
      includedProducts:
        (isRecently || isPersonalized ? props.includedProducts : null) ?? null,
      price: isUpsale ? props.price : null,
      productImage: isAccessories ? props.productImage : null,
      sessionId: (isRecently ? props.sessionId : null) ?? null,
    });
  }, [props]);

  return uniqueProps;
};

type Colors = {
  background: string;
  heading: string;
};

export const getColors = (strategy: Strategy): Colors => {
  switch (strategy) {
    case Strategy.upsale:
      return {
        background: hexToRGB(colors.lightBlue.hex, 0.4),
        heading: colors.blue.hex,
      };
    case Strategy.recently:
      return {
        background: hexToRGB(colors.lightOrange.hex, 0.4),
        heading: colors.darkOrange.hex,
      };
    case Strategy.frequently_bought_together:
      return {
        background: hexToRGB(colors.lightAmber.hex, 0.4),
        heading: colors.darkAmber.hex,
      };
    case Strategy.personalized:
      return {
        background: hexToRGB(colors.lightTurquoise.hex, 0.4),
        heading: colors.darkTurquoise.hex,
      };
    case Strategy.bestseller:
      return {
        background: hexToRGB(colors.lightGreen.hex, 0.4),
        heading: colors.darkGreen.hex,
      };
    case Strategy.popularity:
      return {
        background: hexToRGB(colors.lightGreen.hex, 0.4),
        heading: colors.darkGreen.hex,
      };
    case Strategy.accessories:
      return {
        background: colors.white.hex,
        heading: colors.darkTurquoise.hex,
      };
    default:
      return {
        background: colors.white.hex,
        heading: colors.black.hex,
      };
  }
};

export const stringToStrategyEnum = (key?: string) => {
  switch (key) {
    case Strategy.accessories.toString():
      return Strategy.accessories;
    case Strategy.bestseller.toString():
      return Strategy.bestseller;
    case Strategy.frequently_bought_together.toString():
      return Strategy.frequently_bought_together;
    case Strategy.personalized.toString():
      return Strategy.personalized;
    case Strategy.popularity.toString():
      return Strategy.popularity;
    case Strategy.recently.toString():
      return Strategy.recently;
    case Strategy.upsale.toString():
      return Strategy.upsale;

    default:
      logError("missing", { key });
      return null;
  }
};
